
import { defineComponent, ref, watch } from "vue";
import { oneOf } from "@/uikit/_utils/assist";

export interface TRadioOpts {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
}
export default defineComponent({
  name: "Radio",
  props: [
    "value",
    "label",
    "disabled",
    "name",
    "border",
    "size",
    "options",
    "rules",
    "isHorizontal",
    "checkValidate",
    "reset"
  ],
  setup(props, { emit }) {
    const validator = oneOf("<Radio> size", props.size, [
      "medium",
      "small",
      "mini"
    ]);
    const model = ref<string | number>(props.value);
    const errors = ref<string[]>([]);

    const _handleValue = (value: any) => {
      emit("value", { [props.name]: value || "" });
    };

    const _validation = () => {
      const _errors: string[] = [];
      if (props.rules) {
        props.rules.forEach((rule: any) => {
          const res = rule(model.value);
          if (!res) {
            _errors.push("Something went wrong!");
          }
          if (typeof res === "string") {
            _errors.push(res);
          }
        });
      }
      errors.value = _errors;
    };

    _handleValue(props.value);

    watch(
      () => props.reset,
      val => {
        if (val) _validation();
      },
      { immediate: true }
    );

    watch(
      () => props.checkValidate,
      val => {
        if (val) _validation();
      },
      { immediate: true }
    );

    watch(
      () => model.value,
      newVal => {
        _handleValue(newVal);
        _validation();
      }
    );
    return {
      model,
      errors
    };
  }
});
